import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid2 from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import {useAuth} from 'auth/useAuth'
import axios from 'axios'
import {apiHost} from 'config/apiConfig'
import {IUploadedFile} from 'interfaces/IUploadedFile'
import React from 'react'
import Button from '@mui/material/Button'
import ShowMoreText from './ShowMoreText'
import {grey} from '@mui/material/colors'
import InteractiveLoading from './InteractiveLoading'
import {emFormatWeekdayHourMinuteDate} from 'utils/dateFormatter'
import toastr from 'toastr'
import Chip from '@mui/material/Chip'

export default function UploadedFiles({
  data,
  callback,
}: {
  data: IUploadedFile[]
  callback: () => void
}) {
  const [, , logoutAuth] = useAuth()
  const [processing, setProcessing] = React.useState<boolean>(false)

  const processFile = async (f: IUploadedFile) => {
    setProcessing(true)
    const retval = await axios({
      method: 'POST',
      url: `${apiHost}/api/data/process`,
      data: {id: f.id, name: f.filename},
      timeout: 60 * 4 * 1000, //5 minutes
    })
      .then(async (apiResponse: any) => {
        setProcessing(false)
        toastr.success('File processing started successfully')
        callback()
      })
      .catch((err) => {
        setProcessing(false)
        toastr.error('File processing error')
        callback()
      })
  }

  return (
    <Box mt={3}>
      <Grid2 container spacing={4}>
        <Grid2 size={3}>
          <Typography> File Name: </Typography>
        </Grid2>
        <Grid2 size={1}>
          <Typography>File Size:</Typography>
        </Grid2>
        <Grid2 size={3}>
          <Typography>Uploaded By:</Typography>
        </Grid2>
        <Grid2 size={3}>
          <Typography>Uploaded Date:</Typography>
        </Grid2>
        <Grid2 size={2}>
          <Typography>Processed</Typography>
        </Grid2>
      </Grid2>

      {data ? (
        data.map((d: IUploadedFile, key: number) => {
          return (
            <Box key={key}>
              <Divider />
              <Grid2 container columnSpacing={4} padding={1}>
                <Grid2 size={3}>
                  <ShowMoreText text={d.originalname} maxLen={100} />
                </Grid2>
                <Grid2 size={1}>
                  <Typography variant={'body2'} color={grey[700]}>
                    {d.size}
                  </Typography>
                </Grid2>
                <Grid2 size={3}>
                  <Typography variant={'body2'} color={grey[700]}>
                    {d.user_email}
                  </Typography>
                </Grid2>
                <Grid2 size={3}>
                  <Typography variant={'body2'} color={grey[700]}>
                    {emFormatWeekdayHourMinuteDate(d.uploaded_dttm)}
                  </Typography>
                </Grid2>

                <Grid2 size={2}>
                  <Box>
                    {d.processed ? (
                      <Box display={'flex'} alignContent={'center'}>
                        {d.num_rows && d.num_rows > 0 ? (
                          <Chip
                            label={
                              <Box display={'flex'} alignContent={'center'} justifyItems={'center'}>
                                {' '}
                                <CheckCircleIcon fontSize={'small'} />
                                &nbsp;
                                {d.num_rows}
                              </Box>
                            }
                            size='small'
                            color={'success'}
                            sx={{minWidth: '75px'}}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <>
                        <Button
                          size='small'
                          variant={'outlined'}
                          sx={{fontSize: '12px', mr: 1}}
                          onClick={() => processFile(d)}
                          disabled={Boolean(d.snapshot_id)}
                        >
                          {d.snapshot_id ? 'Processing' : 'Process Now'}
                        </Button>
                        {d.num_rows && d.num_rows > 0 ? (
                          <Chip label={d.num_rows} size='small' color={'warning'} />
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Box>
                </Grid2>
              </Grid2>
            </Box>
          )
        })
      ) : (
        <></>
      )}
      <InteractiveLoading
        loading={processing}
        success={false}
        upperText='processing entries'
        lowerText={
          'You will receive an email when processing is complete, if you received an email already, then try refreshing your browser to see updates'
        }
      />
    </Box>
  )
}
