import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import Box from '@mui/material/Box'
import scaleLogo from '../../assets/scale_logo.png'
import Typography from '@mui/material/Typography'
import {RouteButton} from 'components/routebutton'
import Footer from 'layouts/footer'
import LandingBar from 'layouts/LandingBar'
import Button from '@mui/material/Button'
import {useNavigate} from 'react-router-dom'
import useOnActionPost from 'hooks/common/useOnActionPost'

export default function Landing(): React.ReactElement {
  const navigate = useNavigate()
  const [exec, {data, error, loading}] = useOnActionPost<any>()

  const testAWSEMAIL = () => {
    exec(`/api/webhook/brightdata`, JSON.stringify({snapshot_id: 'some_id'}), {
      onComplete: (res: any) => alert('called the test route'),
      onError: (error: any) => {
        toastr.error('failed test route')
      },
    })
  }

  return (
    <MainLayout header={<LandingBar />} footer={<Footer />}>
      <Box display={'flex'} justifyItems={'center'} justifyContent={'center'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 15,
          }}
        >
          <img
            src={scaleLogo}
            alt='Scale logo'
            style={{
              display: 'flex',
              width: '350px',
              height: 'auto',
            }}
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyItems={'center'} justifyContent={'center'} m={4}>
        <Typography
          variant='h4'
          noWrap
          component='a'
          href='/'
          sx={{
            p: '1em',
            ml: '1em',
            display: {xs: 'flex'},

            letterSpacing: '.5rem',
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          Scalable Asymmetric Lifecycle Engagement (SCALE)
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Button variant='contained' onClick={() => navigate('/login')} size={'large'}>
          login
        </Button>

        {/* <Button variant='contained' onClick={() => testAWSEMAIL()} size={'large'}>
          Test aws email
        </Button> */}
      </Box>
    </MainLayout>
  )
}
